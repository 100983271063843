import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const SectionTwo = styled.section`
  width: 100%;

  box-sizing: border-box;

  padding: 0 92px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 82px;
  margin-bottom: 222px;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 972px) {
    flex-direction: column-reverse;

    padding: 0;
  }

  @media (max-width: 650px) {
    margin: 73px 16px 0 16px;
  }
`;

export const SectionTwoLeft = styled.div`
  li {
    display: flex;
    align-items: center;

    font-weight: 700;

    text-transform: uppercase;

    img {
      margin-right: 16px;
    }

    &:not(:first-child) {
      margin-top: 17px;
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 40px;

    height: 40px;
    width: 182px;

    border-radius: 8.4px;

    background-color: ${colors.blue400};

    color: white;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }

    img {
      margin-left: 10px;
    }
  }

  @media (max-width: 972px) {
    margin-top: 54px;
  }
`;

export const SectionTwoRight = styled.div``;

import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 100vw;

  height: 100%;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1440px;

  height: fit-content;
`;

export const BreadCrumbsWrapper = styled.div`
  width: 100%;
  max-width: 1380px;

  margin-top: 32px;

  height: fit-content;

  @media (max-width: 1440px) {
    margin-left: 29px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1380px;

  display: flex;
  justify-content: center;
`;

export const CompanyNumbersWrapper = styled.div`
  width: 100%;
  max-width: 1380px;

  margin-top: 32px;

  height: fit-content;

  display: flex;
  justify-content: center;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const CompanyNumbersHidden = styled.div`
  width: 100%;
  max-width: 1380px;

  margin: 73px 29px;

  height: fit-content;

  display: none;
  justify-content: center;

  @media (max-width: 650px) {
    display: flex;
  }

  @media (max-width: 500px) {
    margin: 73px 0;
  }

  @media (max-width: 500px) {
    padding: 0 16px;
  }
`;

import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';
import BreadCrumbs from '@/components/BreadCrumbs';
import CompanyNumbers from '@/components/CompanyNumbers';

import {
  PageWrapper,
  PageContainer,
  SectionWrapper,
  BreadCrumbsWrapper,
  CompanyNumbersWrapper,
  CompanyNumbersHidden,
} from '@/styles/pages/area-de-atuacao';

import HeroSection from '@/components/Pages/AreaDeAtuacao/HeroSection';
import SectionTwo from '@/components/Pages/AreaDeAtuacao/SectionTwo';
import SEO from '@/components/SEO';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  {
    name: 'Página inicial',
    relativeURL: '/',
  },
  { name: 'Área de atuação' },
];

const AreaDeAtuacaoPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "area-de-atuacao" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Área de Atuação"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <HeroSection />
            </SectionWrapper>

            <BreadCrumbsWrapper>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsWrapper>

            <CompanyNumbersWrapper>
              <CompanyNumbers />
            </CompanyNumbersWrapper>

            <SectionWrapper>
              <SectionTwo />
            </SectionWrapper>

            <CompanyNumbersHidden>
              <CompanyNumbers />
            </CompanyNumbersHidden>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default AreaDeAtuacaoPage;

/* eslint-disable */
/* prettier-ignore */
import React from 'react';

import { HeroSection } from './styles';

const HeroSectionComponent = (): JSX.Element => {
  return (
    <HeroSection>
      <h1>Área de atuação</h1>
      <p>
        Estamos presentes em 14 estados brasileiros e continuamos expandindo
        nosso alcance.
      </p>
      <a href="https://cliente.generoso.com.br/unidades" target='_blank' rel="noreferrer">Conheça nossas unidades</a>
    </HeroSection>
  );
};

export default HeroSectionComponent;

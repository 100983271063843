import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SectionTwo, SectionTwoLeft, SectionTwoRight } from './styles';

import ListIcon from '@/images/paginas/area-de-atuacao/list-icon.svg';
import { Link } from 'gatsby';

const SectionTwoComponent = (): JSX.Element => {
  return (
    <SectionTwo>
      <SectionTwoLeft>
        <ul>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Rio de Janeiro
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            São Paulo
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Rio Grande do Sul
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Goiás
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Distrito Federal
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Minas Gerais
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Santa Catarina
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Paraná
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Mato Grosso
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Mato Grosso do Sul
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Rondônia
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Acre
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Pará
          </li>
          <li>
            <img src={ListIcon} alt="Ícone de localização" />
            Espírito Santo
          </li>
        </ul>
        <Link to="/contato">Quero ser cliente</Link>
      </SectionTwoLeft>
      <SectionTwoRight>
        <StaticImage
          src="../../../../images/paginas/area-de-atuacao/mapa-brasil.png"
          alt="Mapa do Brasil com pontos onde o Tranporte Generoso atua"
          quality={100}
          placeholder="blurred"
        />
      </SectionTwoRight>
    </SectionTwo>
  );
};

export default SectionTwoComponent;
